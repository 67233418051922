import { Observable, Subscriber } from 'rxjs';
import { Pagination } from '../pagination/pagination';

interface Options {
  useMaxLimit?: boolean;
}

export class Selection<T extends Record<string, any> & { id: number; selected?: boolean }> {
  checkboxAll = false;
  limit = 5000;
  mode: 'all' | 'visible' | null = null;
  _items: T[] = [];
  selectedItems: T[] = [];
  itemKey = 'id';
  loading = false;
  selectAllProgress = 0;

  onSelect: () => void = () => {};

  constructor(
    public pagination: Pagination<T>,
    public options: Options = {}
  ) {}

  get items() {
    return this._items;
  }

  set items(items: T[]) {
    this._items = items;

    for (const item of items) {
      if (this.selectedItems.find((i) => this.compare(i, item))) {
        item.selected = true;
      }
    }
  }

  private compare(first, second) {
    return first[this.itemKey] === second[this.itemKey];
  }

  selectAllFromSearch() {
    this.mode = 'all';
    this.loading = true;

    const progressObserver = new Observable((subscriber: Subscriber<number | null>) => {
      this.selectAllProgress = 0;
      this.pagination.queryAll({ limit: null }, subscriber).then((response) => {
        const newResults = response.results.filter((newItem) =>
          this.items.every((prevItem) => !this.compare(newItem, prevItem))
        );
        this.items = [...this.items, ...newResults];

        this.selectAll();
        this.loading = false;
      });
    });

    progressObserver.subscribe((progress) => {
      this.selectAllProgress = progress || 0;
    });
  }

  selectAll() {
    if (this.mode !== 'all') {
      this.mode = 'visible';
    }
    this.selectedItems = this.items;

    for (const item of this.items) {
      item.selected = true;
    }

    this.checkboxAll = true;

    this.onSelect();
  }

  selectNone() {
    if (this.mode === 'all') {
      this.pagination.queryCurrentPage();
    }

    this.mode = null;
    this.selectedItems = [];
    this.items = this.items.map((item) => Object.assign(item, { selected: false }));

    this.checkboxAll = false;

    this.onSelect();
  }

  toggle(item: T) {
    item.selected = !item.selected;

    const same = this.items.find((i) => i.id === item.id);
    if (same) {
      same.selected = item.selected;
    }

    if (item.selected) {
      if (!this.selectedItems.find((selItem) => this.compare(item, selItem))) {
        this.selectedItems.push(item);
      }
    } else {
      const index = this.selectedItems.findIndex((selItem) => this.compare(item, selItem));

      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }
  }

  removeItem(item: T) {
    const items_index = this.items.findIndex((i) => this.compare(i, item));

    if (items_index !== -1) {
      this.items.splice(items_index, 1);
    }

    const selected_index = this.selectedItems.findIndex((i) => this.compare(i, item));

    if (selected_index !== -1) {
      this.selectedItems.splice(selected_index, 1);
    }
  }
}
