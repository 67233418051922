import { Router, ActivatedRoute } from '@angular/router';

import { AbstractModelService } from '../../abstracts/abstract-model.service';
import { Subscriber, skip } from 'rxjs';

type Callback<T> = (results: T[]) => void;

interface PaginationUrlParams {
  page: boolean;
  state: boolean;
}

export class Pagination<T extends Record<string, any> & { id: number }> {
  limit = 20;
  maxLimit = 5000;
  loaded = false;
  isLoading = false;
  page: number;
  _filter: Record<string, any> = {};

  public count: number;
  public results: T[];

  private internalUpdate = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modelService: AbstractModelService<T>,
    private onResultsChange: Callback<T>,
    public useUrlParams: PaginationUrlParams = { page: false, state: false }
  ) {
    this.page =
      this.useUrlParams.page && this.route.snapshot.queryParams.page ? Number(this.route.snapshot.queryParams.page) : 1;

    // Listen to query changes
    // - Skip first value to avoid triggering query request
    // - Doesn't trigger for internal url updates
    // - React to external url updates like browser back/forward
    this.route.queryParams.pipe(skip(1)).subscribe((params) => {
      if (this.internalUpdate) {
        this.internalUpdate = false;
        return;
      }

      if (this.useUrlParams.page || this.useUrlParams.state) {
        // Gérer le paramètre 'page'
        if (this.useUrlParams.page) {
          const pageParam = Number(params['page']);

          if (!pageParam) {
            this.page = 1;
          } else {
            this.page = pageParam;
          }
        }

        if (this.useUrlParams.state) {
          const stateParam = Number(params['state']);
          if (!stateParam) {
            this._filter.state = 1;
          } else {
            this._filter.state = stateParam;
          }
        }

        this.queryCurrentPage(false);
      }
    });
  }

  queryPage(params: Record<string, any>) {
    this.loaded = false;
    this.isLoading = true;
    const resultPromise = this.modelService.queryPaginated(params);

    return resultPromise
      .then((response) => {
        if (response.results.length === 0 && this.page > 1) {
          return this.queryFirstPage();
        }

        this.results = response.results;

        this.count = response.count;

        this.onResultsChange(this.results);

        return response;
      })
      .finally(() => {
        this.isLoading = false;
        this.loaded = true;
      });
  }

  get filter() {
    return this._filter;
  }

  set filter(filter) {
    this._filter = filter;
  }

  setFilter(key: string, value: number | string | boolean | null) {
    this.filter[key] = value;
  }

  deleteFilter(key: string) {
    delete this.filter[key];
  }

  getResults() {
    return this.results;
  }

  hasNextPage = () => this.count > this.page * this.limit;

  hasPreviousPage = () => this.page > 1;

  queryAll(queryParams = {}, progressObserver: Subscriber<number> | null = null) {
    const params = {
      ...this.filter,
      ...queryParams
    };

    for (const key in params) {
      if (params[key] === undefined || params[key] === null) {
        delete params[key];
      }
    }

    return this.modelService.queryAll(params, progressObserver);
  }

  queryCurrentPage(withUrlUpdate = true) {
    if (!this.page || this.page < 1) {
      this.page = 1;
    }

    const params = {
      limit: this.limit,
      offset: (this.page - 1) * this.limit,
      ...this.filter
    };

    for (const key in params) {
      if (params[key] === undefined || params[key] === null) {
        delete params[key];
      }
    }

    if (withUrlUpdate) {
      this.updateUrlParams();
    }

    return this.queryPage(params);
  }

  updateUrlParams() {
    const urlParams: {
      page?: string;
      state?: string;
    } = {};

    if (this.useUrlParams.state || this.useUrlParams.page) {
      if (this.page !== undefined) {
        urlParams.page = this.page.toString();
      }

      if (this._filter.state !== undefined) {
        urlParams.state = this._filter.state.toString();
      }

      const replaceUrl =
        (this.useUrlParams.page && this.route.snapshot.queryParams.page === undefined) ||
        (this.useUrlParams.state && this.route.snapshot.queryParams.state === undefined);

      this.internalUpdate = true;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: urlParams,
        queryParamsHandling: 'merge',
        replaceUrl
      });
    }
  }

  queryFirstPage = () => {
    this.page = 1;
    this.updateUrlParams();

    return this.queryCurrentPage();
  };

  queryNextPage = () => {
    this.page += 1;
    this.updateUrlParams();

    return this.queryCurrentPage();
  };

  queryPreviousPage = () => {
    this.page -= 1;
    this.updateUrlParams();

    return this.queryCurrentPage();
  };
}
