import { Injectable, Injector } from '@angular/core';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { DialogService } from '../../services/dialog/dialog.service';
import { SubscribeService } from '../subscribe/subscribe.service';
import { AbstractModelService } from 'src/app/abstracts/abstract-model.service';
import { User } from '~models/user/user.model';
import { DialogConfirmComponent } from '~dialogs/confirm/confirm.component';
import { NotificationType } from 'src/app/notification/notification.service';
import { catchError } from 'rxjs';

interface FavoriteResponse {
  lists: {
    liked: number[];
  };
  pinpoints: {
    bookmarked: number[];
    liked: number[];
  };
  users: {
    followed: number[];
  };
}

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractModelService<User> {
  objectClass = User;
  objectPath = '/v3.0/users';

  private user: User = null;
  private _token: string = null;

  constructor(
    public injector: Injector,
    private subscribeService: SubscribeService,
    public dialog: DialogService
  ) {
    super(injector);

    this.errors_message = {
      get: 'user.error-interceptor.ERROR-GETTING-USER',
      update: 'user.error-interceptor.ERROR-UPDATE'
    };
  }

  async followers(id: number) {
    return this.http
      .get(`${this.apiUrl}${this.objectPath}/${id}/followers`)
      .toPromise()
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-GETTING-FOLLOWER')
        );
        return Promise.reject(error);
      });
  }

  async follow(id: number) {
    return this.http
      .post(`${this.apiUrl}${this.objectPath}/${id}/followers`, { id })
      .toPromise()
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-FOLLOWING')
        );
        return Promise.reject(error);
      });
  }

  async unfollow(id: number) {
    return this.http
      .delete(`${this.apiUrl}${this.objectPath}/${id}/followers`)
      .toPromise()
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-UNFOLLOW')
        );
        return Promise.reject(error);
      });
  }

  async me() {
    return this.http
      .get(`${this.apiUrl}${this.objectPath}/me`)
      .toPromise()
      .then(this.itemToModel)
      .catch((error: any) => {
        this.logout();
        return Promise.reject(error);
      });
  }

  async reset(email: string) {
    return this.http
      .post(`${this.apiUrl}${this.objectPath}/reset`, { email })
      .toPromise()
      .catch((error: any) => Promise.reject(error));
  }

  async token() {
    return this.http
      .get<{ token: string }>(`${this.apiUrl}${this.objectPath}/token`)
      .toPromise()
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-GETTING-TOKEN')
        );
        return Promise.reject(error);
      });
  }

  async favorites() {
    return this.http
      .get<FavoriteResponse>(`${this.apiUrl}/v3.0/favorites`)
      .toPromise()
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-GETTING-FAVORITES')
        );
        return Promise.reject(error);
      });
  }

  alias() {
    return this.http.get<User[]>(`${this.apiUrl}/v3.0/users/aliases`).pipe(
      catchError((error) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-GETTING-ALIAS')
        );
        throw error;
      })
    );
  }

  children() {
    return this.http.get<User[]>(`${this.apiUrl}/v3.0/users/children`).pipe(
      catchError((error) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-GETTING-ALIAS')
        );
        throw error;
      })
    );
  }
  async initUser() {
    if (this.user) {
      return this.user;
    }

    const [userInfo, user] = await Promise.all([this.subscribeService.get_user_info(), this.me()]);

    this.user = user;
    this.user.email = userInfo.email;
    this.user.features = userInfo.features;

    return this.user;
  }

  isAuthenticated() {
    return this.user !== null && Object.keys(this.user).length > 0 && !!this.user.id;
  }

  waitForAuthenticated() {
    return new Promise<User>((resolve) => {
      if (this.isAuthenticated()) {
        resolve(this.user);
      } else {
        const interval = setInterval(() => {
          if (this.isAuthenticated()) {
            clearInterval(interval);
            resolve(this.user);
          }
        }, 200);
      }
    });
  }

  getUser() {
    return this.user;
  }

  getToken() {
    return this._token;
  }

  getFeatures() {
    return this.user.features;
  }

  getUserToken() {
    return new Promise((resolve, reject) => {
      if (this._token) {
        resolve(this._token);
      } else {
        this.token()
          .then((reponse) => {
            this._token = reponse.token;
            resolve(this._token);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  async requestAccess(feature: string) {
    let header: string[] = [];

    if (this.translateService.currentLang === 'fr') {
      header = [
        this.translateService.instant('user.modal-request-access.NOT-PERMISSION'),
        this.translateService.instant('user.modal-request-access.FEATURE'),
        `'${feature}'`
      ];
    } else if (this.translateService.currentLang === 'en') {
      header = [
        this.translateService.instant('user.modal-request-access.NOT-PERMISSION'),
        `'${feature}'`,
        this.translateService.instant('user.modal-request-access.FEATURE')
      ];
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        header: header.join(' '),
        body: this.translateService.instant('user.modal-request-access.QUESTION'),
        cancel: this.translateService.instant('common.CANCEL'),
        confirm: this.translateService.instant('common.OK')
      }
    });

    const result = await dialogRef.afterClosed().toPromise();

    if (result) {
      return this.http
        .post(`${this.apiUrl}/v3.0/feature/request_access`, { feature })
        .toPromise()
        .then((response: any) => {
          this.notificationService.launchNotification(
            NotificationType.Success,
            this.translateService.instant('user.notification.ACCESS') +
              feature +
              this.translateService.instant('user.notification.REQUESTED')
          );
          return response;
        });
    }
  }

  logout() {
    this.user = null;
    this._token = null;
    localStorage.removeItem('access_token');
  }

  queryPaginated(params: Record<string, any>) {
    let req: any, paramsCopy: any;
    if (params.following) {
      paramsCopy = Object.assign({}, params);
      delete paramsCopy.following;
      req = this.http.get(`${this.apiUrl}${this.objectPath}/${params.following}/followers`, { params: paramsCopy });
    } else {
      req = this.http.get(
        `${this.apiUrl}${this.objectPath}${params.query || params.followed_by || params.is_child ? '/search' : ''}`,
        { params: params }
      );
    }

    return req
      .toPromise()
      .then(this.responseToModel)
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('user.error-interceptor.ERROR-GETTING-USERS')
        );
        return Promise.reject(error);
      });
  }
}
