import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '~models/user/user.model';
import { UserService } from '~services/user/user.service';
import { Pagination } from '~core/pagination/pagination';
import { AbstractDialog } from '../../../abstracts/abstract-dialog';

@Component({
  selector: 'dialog-switch-user',
  templateUrl: './switch-user.component.html'
})
export class DialogSwitchUserComponent extends AbstractDialog<void, User> {
  users: User[];
  currentUser: User;
  query = '';
  inputDebounce: Subject<void> = new Subject<void>();
  pagination: Pagination<User>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<DialogSwitchUserComponent, User>,
    public userService: UserService
  ) {
    super(null, dialogRef);

    this.pagination = new Pagination<User>(
      this.router,
      this.route,
      this.userService,
      this.handleUserResults.bind(this)
    );
    this.userService.waitForAuthenticated().then((user) => {
      this.currentUser = user;
    });

    this.inputDebounce.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.pagination.setFilter('query', this.query);

      this.pagination.queryFirstPage();
    });
  }

  handleUserResults(results: User[]) {
    this.users = results.filter((u) => u.id !== this.currentUser.id);
  }
}
