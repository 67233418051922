<div mat-dialog-title class="text-center" [innerHTML]="data.header"></div>

<div class="flex w-full items-center justify-center">
  <mat-divider class="w-[90%]"></mat-divider>
</div>

<div mat-dialog-content>
  <span class="inline" [innerHTML]="data.body"> </span>
</div>

<div class="flex w-full items-center justify-center">
  <mat-divider class="w-[90%]"></mat-divider>
</div>

<mat-dialog-actions class="flex w-full items-center justify-center" align="end">
  <button mat-stroked-button [mat-dialog-close] class="w-1/4" *ngIf="data.cancel">
    {{ data.cancel }}
  </button>

  <button mat-flat-button [color]="data.confirmType || 'primary'" [mat-dialog-close]="true" class="w-1/4">
    {{ data.confirm }}
  </button>
</mat-dialog-actions>
