import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {
  MatDateFormats,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  NativeDateAdapter
} from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule, MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';

const INDOOR_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="640" height="640" viewBox="0 0 640 640" xml:space="preserve">
<defs>
</defs>
<g transform="matrix(0.9212608527 0 0 0.9212608527 320 320)" id="UoP2wGz1QK1zYdoJEdDyE"  >
<g style="" vector-effect="non-scaling-stroke"   >
		<g transform="matrix(0.1333333333 0 0 -0.1333333333 -181.8847088505 -122.4208247945)" id="YVZlyGXayseDJTkRDx3v1"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: currentColor; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-1195.8646836211, -3478.1561859588)" d="M 976 4925 C 488 4833 113 4461 15 3972 C -9 3853 -1 3559 29 3447 C 129 3073 393 2771 740 2634 L 845 2592 L 980 2327 C 1054 2181 1124 2052 1134 2041 C 1164 2008 1232 2012 1262 2048 C 1274 2064 1343 2193 1414 2336 L 1542 2595 L 1639 2632 C 1835 2708 2023 2852 2154 3028 C 2424 3387 2467 3878 2263 4285 C 2201 4409 2142 4490 2036 4595 C 1882 4748 1702 4851 1490 4907 C 1368 4939 1102 4948 976 4925 z M 1380 4744 C 1845 4656 2187 4262 2207 3790 C 2210 3725 2207 3642 2201 3600 C 2144 3231 1894 2922 1547 2793 C 1497 2774 1442 2748 1425 2735 C 1404 2719 1366 2653 1295 2511 L 1195 2310 L 1094 2512 C 1029 2640 981 2723 964 2737 C 949 2749 902 2771 861 2786 C 545 2897 315 3143 213 3475 C 188 3559 186 3577 186 3745 C 186 3913 188 3931 213 4014 C 272 4203 356 4345 488 4472 C 637 4617 796 4701 1000 4745 C 1089 4764 1279 4763 1380 4744 z" stroke-linecap="round" />
</g>
		<g transform="matrix(0.1333333333 0 0 -0.1333333333 -181.9686841265 -157.9830261887)" id="ZyGco6jQ37bcdZwWjYxId"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: currentColor; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-1195.2348690513, -3744.8726964153)" d="M 1097 4249 C 1072 4234 1051 4181 1057 4148 C 1066 4100 1094 4086 1188 4079 C 1295 4071 1356 4045 1426 3975 C 1587 3815 1538 3538 1331 3441 C 1216 3387 1070 3414 968 3509 C 874 3595 835 3733 874 3846 C 895 3909 894 3942 870 3968 C 829 4012 764 4008 729 3960 C 683 3898 667 3735 695 3625 C 729 3492 819 3370 934 3301 C 1132 3182 1397 3216 1560 3381 C 1839 3664 1708 4145 1325 4245 C 1257 4262 1124 4265 1097 4249 z" stroke-linecap="round" />
</g>
		<g transform="matrix(0.1333333333 0 0 -0.1333333333 26.7494716969 44.6820631049)" id="acVNN5CZX2gdX-_0ifDKs"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: currentColor; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-2760.6210377268, -2224.8845267132)" d="M 2535 4245 C 2507 4218 2501 4157 2523 4129 C 2553 4090 2541 4090 3759 4090 L 4940 4090 L 4940 2225 L 4940 360 L 4610 360 L 4280 360 L 4280 394 C 4280 412 4277 442 4274 459 L 4268 490 L 4487 490 C 4695 490 4709 491 4734 511 L 4760 531 L 4760 2221 L 4760 3911 L 4735 3935 L 4711 3960 L 3635 3960 L 2559 3960 L 2535 3935 L 2510 3911 L 2510 3225 L 2510 2539 L 2533 2516 C 2553 2496 2571 2492 2653 2486 C 2779 2477 2846 2459 2949 2409 C 3047 2361 3163 2253 3218 2158 C 3255 2096 3300 1972 3300 1934 L 3300 1910 L 2930 1910 L 2559 1910 L 2535 1885 L 2510 1861 L 2510 1265 L 2510 670 L 1730 670 L 951 670 L 948 1266 L 945 1862 L 917 1886 C 880 1918 830 1918 793 1886 L 765 1862 L 765 1202 L 765 542 L 789 516 L 813 490 L 2082 490 L 3350 490 L 3350 425 L 3350 360 L 1970 360 L 590 360 L 589 1448 C 589 2263 586 2544 577 2570 C 552 2643 452 2649 417 2580 C 401 2547 400 2475 402 1388 L 405 232 L 429 206 L 453 180 L 1966 180 C 3439 180 3478 180 3501 199 L 3525 218 L 3528 594 C 3531 1021 3521 983 3621 954 C 3753 915 3884 825 3965 719 C 4053 602 4093 487 4105 317 C 4116 168 4071 180 4615 180 C 5057 180 5068 180 5094 201 L 5120 221 L 5120 2221 L 5120 4221 L 5095 4245 L 5071 4270 L 3815 4270 L 2559 4270 L 2535 4245 z M 4580 2225 L 4580 670 L 4397 670 L 4213 670 L 4160 758 C 4036 960 3826 1105 3596 1145 C 3458 1169 3414 1166 3379 1131 L 3350 1102 L 3350 886 L 3350 670 L 3020 670 L 2690 670 L 2690 1200 L 2690 1730 L 3055 1730 C 3300 1730 3427 1734 3443 1741 C 3485 1760 3494 1790 3487 1893 C 3468 2187 3282 2455 3009 2585 C 2923 2625 2796 2660 2733 2660 L 2690 2660 L 2690 3220 L 2690 3780 L 3635 3780 L 4580 3780 L 4580 2225 z" stroke-linecap="round" />
</g>
</g>
</g>
<g transform="matrix(1 0 0 1 152.6201635585 173.4064120373)" id="9pid9zUAMcJFbkyd5C-89"  >
<path style="stroke: currentColor; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: currentColor; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-63.05391, -63.05391)" d="M 63.05391 0 C 97.85967 0 126.10782 28.24815 126.10782 63.05391 C 126.10782 97.85967 97.85967000000001 126.10782 63.05391 126.10782 C 28.248150000000003 126.10782 0 97.85967000000001 0 63.05391 C 0 28.248150000000003 28.24815 0 63.05391 0 z M 22.21185 63.97289 C 22.21185 86.2478 40.29003 104.32598 62.56494 104.32598 C 84.83985 104.32598 102.91803 86.2478 102.91803 63.97289 C 102.91803 41.69798 84.83985 23.619799999999998 62.56494 23.619799999999998 C 40.29003 23.619799999999998 22.21185 41.69798 22.21185 63.97289 z" stroke-linecap="round" />
</g>
</svg>`;

const CUSTOM_FACEBOOK = `<svg class="w-4 h-4 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
<path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
</svg>`;

const CUSTOM_TWITTER = `<svg class="w-4 h-4 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
<path fill="currentColor" d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"/>
</svg>`;

const CUSTOM_MAGNET = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M191.98 463.79v-48m-101.82 5.82 33.94-33.94m-76.12-67.88h48"></path><path d="m267.56 312.32-31.11 31.11a16 16 0 0 0 0 22.63l45.26 45.25a16 16 0 0 0 22.62 0l31.12-31.11a4 4 0 0 0 0-5.66l-62.23-62.22a4 4 0 0 0-5.66 0zM131.8 176.55l-31.11 31.12a16 16 0 0 0 0 22.62l45.25 45.26a16 16 0 0 0 22.63 0l31.11-31.11a4 4 0 0 0 0-5.66l-62.22-62.23a4 4 0 0 0-5.66 0zm297.05-93.27a144 144 0 0 0-203.71-.06l-65.06 65.05a4 4 0 0 0 0 5.66l62.23 62.22a4 4 0 0 0 5.66 0l65-65.05a48 48 0 0 1 68.46.59c18.3 18.92 17.47 49.24-1.14 67.85L295.85 284a4 4 0 0 0 0 5.66l62.22 62.23a4 4 0 0 0 5.66 0l64.08-64.08c56.37-56.34 57.37-148.13 1.04-204.53z"></path></svg>`;

const MATERIAL_MODULES = [
  LayoutModule,
  MatSlideToggleModule,
  MatButtonModule,
  FormsModule,
  MatCheckboxModule,
  MatIconModule,
  MatMenuModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatInputModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatSliderModule,
  ClipboardModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatRadioModule,
  MatExpansionModule,
  DragDropModule,
  MatToolbarModule,
  MatButtonToggleModule,
  MatTableModule,
  MatPaginatorModule,
  MatGridListModule,
  MatSortModule
];

const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: MATERIAL_MODULES,
  exports: MATERIAL_MODULES,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {
      provide: NativeDateAdapter,
      useClass: MatNativeDateModule,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: { hideSingleSelectionIndicator: true }
    }
  ]
})
export class MaterialModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
    const roundedFontSetClasses = defaultFontSetClasses
      .filter((fontSetClass) => fontSetClass !== 'material-icons')
      .concat(['material-symbols-rounded']);
    iconRegistry.setDefaultFontSetClass(...roundedFontSetClasses);

    iconRegistry.addSvgIconLiteral('indoor', sanitizer.bypassSecurityTrustHtml(INDOOR_ICON));
    iconRegistry.addSvgIconLiteral('custom_facebook', sanitizer.bypassSecurityTrustHtml(CUSTOM_FACEBOOK));
    iconRegistry.addSvgIconLiteral('custom_twitter', sanitizer.bypassSecurityTrustHtml(CUSTOM_TWITTER));
    iconRegistry.addSvgIconLiteral('custom_magnet', sanitizer.bypassSecurityTrustHtml(CUSTOM_MAGNET));
    // iconRegistry.addSvgIcon('thumbs-up', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/thumbs-up.svg')); ---> for external svg
  }
}
