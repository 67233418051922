export class User {
  id: number;

  firstname: string;
  bio: string;
  location: string;

  profile_url: string;
  photo_url: string;

  role: number;

  created: string;
  updated: string;

  email: string;

  profile_file: {
    content: string;
    name: string;
    type: string;
  };

  features: {
    api: boolean;
    ar: boolean;
    feed: boolean;
    portal: boolean;
    print: boolean;
    spreadsheet: boolean;
    statistics: boolean;
    store_locator: boolean;
    import_interval: boolean;
  } = {
    api: false,
    ar: false,
    feed: false,
    portal: false,
    print: false,
    spreadsheet: false,
    statistics: false,
    store_locator: false,
    import_interval: false
  };

  current_password: string;
  password: string;

  photo_file: {
    content: string;
    name: string;
    type: string;
  };

  constructor(data: any = {}) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this[key] = data[key];
      }
    }
  }

  hasFeature(feature: string) {
    return this.features[feature];
  }
}
