<div class="notification">
  <div class="icon">
    <mat-icon>{{ notification.icon }}</mat-icon>
  </div>
  <div class="msg">{{ notification.message }}</div>

  <button *ngIf="!notification.actions" class="close" mat-icon-button (click)="snackBarRef.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
  <span matSnackBarActions *ngIf="notification.actions" style="display: flex">
    <button *ngFor="let action of notification.actions" mat-button matSnackBarAction (click)="action.callback()">
      {{ action.label }}
    </button>
  </span>
</div>
