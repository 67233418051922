import { FeedTypeImagePipe } from '~pipes/feed-type-image.pipe';
import { RoundPipe } from '~pipes/round.pipe';
import { SafeHtmlPipe } from '~pipes/safe-html.pipe';
import { FilterPipe } from '~pipes/filter.pipe';
import { CutPipe } from '~pipes/cut.pipe';
import { TimeagoPipe } from '~pipes/timeago.pipe';
import { SafeUrlPipe } from '~pipes/safe-url.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [FeedTypeImagePipe, RoundPipe, SafeHtmlPipe, FilterPipe, CutPipe, TimeagoPipe, SafeUrlPipe],
  exports: [FeedTypeImagePipe, RoundPipe, SafeHtmlPipe, FilterPipe, CutPipe, TimeagoPipe, SafeUrlPipe],
  providers: [FeedTypeImagePipe, RoundPipe, SafeHtmlPipe, FilterPipe, CutPipe, TimeagoPipe, SafeUrlPipe]
})
export class PipeModule {}
