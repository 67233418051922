import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService } from '~services/config/config.service';
import { NotificationService, NotificationType } from '../notification/notification.service';
import { Observable, Subscriber, catchError, map } from 'rxjs';

type Newable<T> = new (...args: any[]) => T;

export interface Response<T = any> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export type CallbackListener = (data?: any) => void;
interface ErrorMessage {
  get?: string;
  save?: string;
  update?: string;
  delete?: string;
}

export interface BulkError {
  id: number;
  detail: string;
}

export interface BulkResponseError {
  errors: BulkError[];
  total: number;
}

export abstract class AbstractModelService<T extends Record<string, any> & { id: number }> {
  constructor(public injector: Injector) {
    this.http = injector.get(HttpClient);
    this.translateService = injector.get(TranslateService);
    this.configService = injector.get(ConfigService);
    this.notificationService = injector.get(NotificationService);

    this.apiUrl = this.configService.get('API_CONFIG').API_URL;
  }

  abstract objectClass: Newable<T>;
  abstract objectPath: string;

  http: HttpClient;
  translateService: TranslateService;
  configService: ConfigService;
  notificationService: NotificationService;

  apiUrl: string;
  listenerList: Record<string, CallbackListener[]> = {};

  errors_message?: ErrorMessage;

  public QUERY_ALL_SLICE = 50;

  abstract queryPaginated(params: Record<string, any>): Promise<Response<T>>;

  public itemToModel = (data: any): T => new this.objectClass(data);

  public responseToModel = (response: Response): Response<T> =>
    Object.assign(response, {
      results: response.results.map(this.itemToModel)
    });

  async queryAll(params: Record<string, any> = {}, progressObserver: Subscriber<number> | null = null) {
    const totalResults: T[] = [];

    let count = 0;
    let offset = 0;

    do {
      const response = await this.queryPaginated({
        ...params,
        limit: this.QUERY_ALL_SLICE,
        offset
      });
      count = count || response.count;

      totalResults.push(...response.results);

      if (progressObserver) {
        progressObserver.next(totalResults.length / count);
      }

      offset += this.QUERY_ALL_SLICE;
    } while (totalResults.length < count);

    if (progressObserver) {
      progressObserver.next(1);
    }

    return {
      count: count,
      results: totalResults
    };
  }

  getById(id: number): Observable<T> {
    return this.http
      .get(`${this.apiUrl}${this.objectPath}/${id}`)
      .pipe(map(this.itemToModel), catchError(this.handleError('get')));
  }

  async get(params: Record<string, any> = {}): Promise<Response<T>> {
    return this.http
      .get<Response>(`${this.apiUrl}${this.objectPath}`, { params })
      .toPromise()
      .then(this.responseToModel)
      .catch((error) => {
        if (this.errors_message && this.errors_message.get) {
          this.notificationService.launchNotification(
            NotificationType.Danger,
            this.translateService.instant(this.errors_message.get)
          );
        }

        return Promise.reject(error);
      });
  }

  save(object: Omit<T, 'id' | 'user'>): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}${this.objectPath}`, object)
      .pipe(map(this.itemToModel), catchError(this.handleError('save')));
  }

  delete(id: number) {
    return this.http.delete(`${this.apiUrl}${this.objectPath}/${id}`).pipe(catchError(this.handleError('delete')));
  }

  updateObservable(object: T): Observable<T> {
    return this.http
      .put<T>(`${this.apiUrl}${this.objectPath}/${object.id}`, object)
      .pipe(map(this.itemToModel), catchError(this.handleError('update')));
  }

  async update(object: T): Promise<T> {
    return this.http
      .put<T>(`${this.apiUrl}${this.objectPath}/${object.id}`, object)
      .toPromise()
      .then(this.itemToModel)
      .catch((error) => {
        if (this.errors_message && this.errors_message.update) {
          this.notificationService.launchNotification(
            NotificationType.Danger,
            this.translateService.instant(this.errors_message.update)
          );
        }

        return Promise.reject(error);
      });
  }

  handleError = (type: keyof ErrorMessage) => (error: Error) => {
    if (this.errors_message && this.errors_message[type]) {
      this.notificationService.launchNotification(
        NotificationType.Danger,
        this.translateService.instant(this.errors_message[type as keyof ErrorMessage]!)
      );
    }

    throw error;
  };

  /**
   * Listeners
   */

  /**
   * Adds a listener to a specified event type.
   * @param {string} type The event type to add a listen for.
   * @param {Function} listener The function to be called when the event is fired.
   * @returns {Object} `this`
   */
  on(type: string, listener: CallbackListener) {
    if (this.listenerList[type]) {
      this.listenerList[type].push(listener);
    }
    return this;
  }

  /**
   * Adds a listener to a specified event type. It will run only once
   * @param {string} type The event type to add a listen for.
   * @param {Function} listener The function to be called when the event is fired.
   * @returns {Object} `this`
   */
  once(type: string, listener: CallbackListener) {
    const temporaryListener = (...args: any[]) => {
      listener(...args);
      this.off(type, temporaryListener);
    };

    if (this.listenerList[type]) {
      this.listenerList[type].push(temporaryListener);
    }
    return this;
  }

  /**
   * Removes a previously registered event listener.
   *
   * @param {string} type The event type to remove listeners for.
   * @param {Function} listener The listener function to remove.
   * @returns {Object} `this`
   */
  off(type: string, listener: CallbackListener) {
    if (this.listenerList[type]) {
      const index = this.listenerList[type].indexOf(listener);
      if (index !== -1) {
        this.listenerList[type].splice(index, 1);
      }
    }
    return this;
  }

  /**
   * Adds a listener that will be called to a specified event type.
   *
   * @param {string} type The event type to listen for.
   * @param {Object} object The object to notify.
   * @returns {Object} `this`
   */
  emit(type: string, object: any) {
    if (this.listenerList[type]) {
      for (const listener of this.listenerList[type]) {
        listener(object);
      }
    }
    return this;
  }
}
