import { Injectable } from '@angular/core';

import { ConfigInterface } from 'src/app/interfaces/config.interface';
import { environment } from 'src/environments/environment';

// declare let CONFIGURATION: ConfigInterface;

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private config: ConfigInterface;
  private _isProduction = environment.production;

  constructor() {
    this.config = environment.config;
  }

  isProduction() {
    return this._isProduction;
  }

  get<T extends keyof ConfigInterface>(key: T) {
    return this.config[key];
  }
}
