import { Component } from '@angular/core';

import { AbstractDialog } from '../../../abstracts/abstract-dialog';

interface DialogData {
  header: string;
  body: string;
  cancel?: string;
  confirm: string;
  confirmType?: 'warn' | 'primary' | 'accent';
}

@Component({
  selector: 'dialog-confirm',
  templateUrl: './confirm.component.html'
})
export class DialogConfirmComponent extends AbstractDialog<DialogData, boolean> {}
