import { Component, Input } from '@angular/core';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule],
  templateUrl: './paginator.component.html'
})
export class PaginatorComponent {
  @Input() pagination;

  ceil(value: number) {
    return Math.ceil(value);
  }
}
