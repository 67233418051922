<div mat-dialog-title style="text-align: center">
  <p *ngIf="data.step === 1">
    {{ 'help.tutorial.modal-welcome-step1.WELCOME' | translate }}
  </p>

  <p *ngIf="data.step === 2">
    {{ 'help.tutorial.modal-welcome-step2.CREATE-PLUG' | translate }}
  </p>

  <p *ngIf="data.step === 3">
    {{ 'help.tutorial.modal-welcome-step3.EDIT-CUSTOMIZE' | translate }}
  </p>

  <p *ngIf="data.step === 4">
    {{ 'help.tutorial.modal-welcome-step4.SET-MAP' | translate }}
  </p>

  <p *ngIf="data.step === 5">
    {{ 'help.tutorial.modal-welcome-step5.EMBED-MAP' | translate }}
  </p>
</div>

<mat-divider></mat-divider>

<!-- Step 1 -->
<div mat-dialog-content *ngIf="data.step === 1" style="width: 600px; height: 325px">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <div class="mb-5 flex flex-col items-center justify-center text-gray-900">
      <span>
        {{ 'help.tutorial.modal-welcome-step1.EASY-POWERFUL' | translate }}
      </span>
      <span class="font-bold">
        {{ 'help.tutorial.modal-welcome-step1.BUILD-CONNECTED' | translate }}
      </span>
    </div>

    <div class="mb-5 flex flex-col items-center justify-center text-gray-900">
      <span>
        {{ 'help.tutorial.modal-welcome-step1.FOLLOW' | translate }}
      </span>
      <span class="font-bold">
        {{ 'help.tutorial.modal-welcome-step1.MINUTE' | translate }}
      </span>
    </div>

    <div class="flex items-center justify-center">
      <img alt="Tutorial illustration" src="/img/help/card1_illu1.png" />
    </div>
  </div>
</div>

<!-- Step 2 -->
<div mat-dialog-content *ngIf="data.step === 2" style="width: 600px; height: 325px">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <div class="mb-5 flex flex-col items-center justify-center text-gray-900">
      <span class="font-bold">
        {{ 'help.tutorial.modal-welcome-step2.WAYS' | translate }}
      </span>
    </div>

    <div class="mb-5 flex w-full items-center justify-center gap-x-4">
      <div class="flex w-1/3 items-center justify-center">
        <img alt="Tutorial illustration" src="/img/help/card2_illu1.png" />
      </div>
      <div class="flex w-1/3 items-center justify-center">
        <img alt="Tutorial illustration" src="/img/help/card2_illu2.png" />
      </div>
      <div class="flex w-1/3 items-center justify-center">
        <img alt="Tutorial illustration" src="/img/help/card2_illu3.png" />
      </div>
    </div>

    <div class="flex w-full items-center justify-center gap-x-4 text-gray-500">
      <div class="flex w-1/3 flex-col items-center justify-center text-center">
        <span> {{ 'help.tutorial.modal-welcome-step2.DOCUMENTS' | translate }}</span>
        <span> {{ 'help.tutorial.modal-welcome-step2.XLSX' | translate }}</span>
      </div>
      <div class="flex w-1/3 flex-col items-center justify-center text-center">
        <span> {{ 'help.tutorial.modal-welcome-step2.APPS' | translate }}</span>
        <span> {{ 'help.tutorial.modal-welcome-step2.APPS-EX' | translate }}</span>
      </div>
      <div class="flex w-1/3 flex-col items-center justify-center text-center">
        <span> {{ 'help.tutorial.modal-welcome-step2.CREATE-POINTS' | translate }}</span>
        <span> {{ 'help.tutorial.modal-welcome-step2.BY-HAND' | translate }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Step 3 -->
<div mat-dialog-content *ngIf="data.step === 3" style="width: 600px; height: 325px">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <span class="font-bold text-gray-900">
      {{ 'help.tutorial.modal-welcome-step3.CUSTOMIZE-PPS' | translate }}
    </span>
    <span class="mb-5 text-gray-900">
      {{ 'help.tutorial.modal-welcome-step3.CHANGE' | translate }}
    </span>
    <img alt="Tutorial illustration" src="/img/help/card3_illu1.png" />
  </div>
</div>

<!-- Step 4 -->
<div mat-dialog-content *ngIf="data.step === 4" style="width: 600px; height: 325px">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <div class="mb-5 flex flex-col items-center justify-center text-gray-900">
      <span>
        {{ 'help.tutorial.modal-welcome-step4.CONTENT-GENERATED' | translate }}
      </span>

      <span class="font-bold">
        {{ 'help.tutorial.modal-welcome-step4.BASIC-INFO' | translate }}
      </span>

      <span class="font-bold">
        {{ 'help.tutorial.modal-welcome-step4.YOUR-MAP' | translate }}
      </span>
    </div>

    <img alt="Tutorial illustration" src="/img/help/card4_illu1.png" />
  </div>
</div>

<!-- Step 5 -->
<div mat-dialog-content *ngIf="data.step === 5" style="width: 600px; height: 325px">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <div class="flex items-center justify-center gap-x-1 text-gray-900">
      <span> {{ 'help.tutorial.modal-welcome-step5.YOUR' | translate }}</span>
      <span class="font-bold"> {{ 'help.tutorial.modal-welcome-step5.FULLY-RESPO' | translate }}</span>
      <span> {{ 'help.tutorial.modal-welcome-step5.MAP-READY' | translate }}</span>
    </div>

    <div class="flex items-center justify-center gap-x-1 text-gray-900">
      <span> {{ 'help.tutorial.modal-welcome-step5.EMBED' | translate }}</span>
      <span> {{ 'help.tutorial.modal-welcome-step5.SNIPPET' | translate }}</span>
    </div>

    <div class="mb-5 flex items-center justify-center gap-x-1 text-gray-900">
      <span class="font-bold"> {{ 'help.tutorial.modal-welcome-step5.NO-CODE' | translate }}</span>
    </div>

    <img alt="Tutorial illustration" src="/img/help/card5_illu1.png" />
  </div>
</div>

<mat-divider></mat-divider>

<div mat-dialog-actions>
  <div class="flex w-full items-center justify-between">
    <button *ngIf="data.step === 1" mat-stroked-button class="w-1/3" [mat-dialog-close]>
      {{ 'help.tutorial.common.SKIP' | translate }}
    </button>

    <button *ngIf="data.step !== 1" mat-stroked-button class="w-1/3" (click)="prev()">
      {{ 'help.tutorial.common.PREV' | translate }}
    </button>

    <span> {{ data.step }} / 5 </span>

    <button *ngIf="data.step !== 5" mat-stroked-button class="w-1/3" (click)="next()" cdkFocusInitial>
      {{ 'help.tutorial.common.NEXT' | translate }}
    </button>

    <button
      *ngIf="data.step === 5"
      mat-flat-button
      color="primary"
      class="w-1/3"
      (click)="next()"
      [mat-dialog-close]
      cdkFocusInitial>
      {{ 'help.tutorial.modal-welcome-step5.CREATE-CONTENT' | translate }}
    </button>
  </div>
</div>
