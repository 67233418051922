<div mat-dialog-title style="text-align: center">
  {{ 'list.modal.modal-create-options.CREATE-CONTENT' | translate }}
</div>

<mat-divider></mat-divider>

<div mat-dialog-content style="width: 600px">
  <div class="flex h-full w-full flex-col items-center justify-center">
    <div class="mb-5 flex flex-col items-center justify-center text-gray-900">
      <span class="font-bold">
        {{ 'list.modal.modal-create-options.CREATED-PPS-BELONG' | translate }}
      </span>
    </div>

    <div class="mb-5 flex w-full items-center justify-center gap-x-4">
      <div class="flex w-1/3 items-center justify-center">
        <img
          alt="Illustration create"
          src="/img/help/card2_illu1.png"
          [routerLink]="user.hasFeature('spreadsheet') ? '/import-sheet' : ''"
          [mat-dialog-close]="user.hasFeature('spreadsheet') ? true : false"
          [ngClass]="user.hasFeature('spreadsheet') ? 'cursor-pointer hover:scale-125' : 'opacity-75'" />
      </div>
      <div class="flex w-1/3 items-center justify-center">
        <img
          alt="Illustration create"
          src="/img/help/card2_illu2.png"
          [routerLink]="user.hasFeature('feed') ? '/apps' : ''"
          [mat-dialog-close]="user.hasFeature('feed') ? true : false"
          [ngClass]="user.hasFeature('feed') ? 'cursor-pointer hover:scale-125' : 'opacity-75'" />
      </div>
      <div class="flex w-1/3 items-center justify-center">
        <img
          alt="Illustration create"
          src="/img/help/card2_illu3.png"
          [mat-dialog-close]
          routerLink="/lists/form"
          class="cursor-pointer hover:scale-125" />
      </div>
    </div>

    <div class="flex w-full items-center justify-center gap-x-4 text-gray-500">
      <div class="flex w-1/3 flex-col items-center justify-center text-center">
        <span> {{ 'help.tutorial.modal-welcome-step2.DOCUMENTS' | translate }}</span>
        <span> {{ 'help.tutorial.modal-welcome-step2.XLSX' | translate }}</span>
      </div>
      <div class="flex w-1/3 flex-col items-center justify-center text-center">
        <span> {{ 'help.tutorial.modal-welcome-step2.APPS' | translate }}</span>
        <span> {{ 'help.tutorial.modal-welcome-step2.APPS-EX' | translate }}</span>
      </div>
      <div class="flex w-1/3 flex-col items-center justify-center text-center">
        <span> {{ 'help.tutorial.modal-welcome-step2.CREATE-POINTS' | translate }}</span>
        <span> {{ 'help.tutorial.modal-welcome-step2.BY-HAND' | translate }}</span>
      </div>
    </div>
  </div>
</div>
