import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '../config/config.service';

interface UserInfoResponse {
  email: string;
  features: {
    api: boolean;
    ar: boolean;
    feed: boolean;
    portal: boolean;
    print: boolean;
    store_locator: boolean;
    spreadsheet: boolean;
    import_interval: boolean;
    statistics: boolean;
  };
}

@Injectable({ providedIn: 'root' })
export class SubscribeService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  async get_user_info() {
    return this.http
      .get<UserInfoResponse>(this.configService.get('API_CONFIG').API_URL + '/v3.0/subscribe/info')
      .toPromise();
  }
}
