import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'timeago',
  pure: false
})
export class TimeagoPipe implements PipeTransform {
  transform(datestring: Date | string) {
    if (datestring instanceof Date || datestring.indexOf('Z')) {
      return dayjs(datestring).local().fromNow();
    } else if (datestring) {
      return dayjs(datestring + ' Z')
        .local()
        .fromNow();
    }
  }
}
