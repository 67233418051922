import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NotificationComponent } from './notification.component';

export enum NotificationType {
  Info = 'alert-info',
  Danger = 'alert-danger',
  Success = 'alert-success',
  Warning = 'alert-warning'
}

export type ActionsType = {
  label: string;
  callback: () => void;
}[];

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationIcon = {
    'alert-info': 'info',
    'alert-danger': 'cancel',
    'alert-success': 'check_circle',
    'alert-warning': 'warning'
  };

  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  launchNotification(type: NotificationType, message: string, duration = 5000) {
    this.zone.run(() => {
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          icon: this.notificationIcon[type],
          message
        },
        duration: duration,
        panelClass: [type],
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    });
  }

  launchActionNotification(type: NotificationType, message: string, actions: ActionsType) {
    this.zone.run(() => {
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          icon: this.notificationIcon[type],
          message,
          actions
        },
        panelClass: [type],
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    });
  }

  clearNotification() {
    this.snackBar.dismiss();
  }
}
