import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ListService } from '~services/list/list.service';
import { DialogService } from '~services/dialog/dialog.service';
import { AbstractDialog } from '../../../abstracts/abstract-dialog';

export interface DialogData {
  step: number;
}

@Component({
  selector: 'dialog-tutorial',
  templateUrl: './tutorial.component.html'
})
export class DialogTutorialComponent extends AbstractDialog<DialogData, void> {
  constructor(
    public dialogRef: MatDialogRef<DialogTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: DialogService,
    private listService: ListService
  ) {
    super(data, dialogRef);
    data.step = data.step < 1 ? 1 : data.step > 5 ? 5 : data.step;
  }

  prev() {
    if (this.data.step > 1) {
      this.data.step--;
    }
  }

  next() {
    if (this.data.step < 5) {
      this.data.step++;
    } else {
      this.listService.openCreateOptions();
    }
  }
}
