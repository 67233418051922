import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feedTypeImage',
  pure: false
})
export class FeedTypeImagePipe implements PipeTransform {
  images: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
    33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 45, 47, 48, 49, 50, 51, 52, 53, 54, 55, 54, 56, 62, 66, 74, 77
  ];

  transform(id: number): string {
    return `img/app-icon/${this.images.includes(id) ? id : 0}.png`;
  }
}
