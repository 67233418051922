import { Component, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import { ListService } from '~services/list/list.service';
import { Pagination } from '~core/pagination/pagination';
import { Selection } from '~core/selection/selection';
import { List } from '~models/list/list.model';
import { AbstractDialog } from '../../../../abstracts/abstract-dialog';

@Component({
  selector: 'dialog-list-choice',
  templateUrl: './choice.component.html'
})
export class DialogListChoiceComponent extends AbstractDialog<void, List[]> {
  listName = '';
  lists: List[] = [];

  pagination: Pagination<List>;
  selection: Selection<List>;

  listService: ListService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<DialogListChoiceComponent, List[]>,
    public injector: Injector
  ) {
    super(null, dialogRef);

    this.listService = injector.get(ListService);
    this.pagination = new Pagination<List>(
      this.router,
      this.route,
      this.listService,
      this.handlePaginateChange.bind(this)
    );
    this.pagination.limit = 10;
    this.selection = new Selection<List>(this.pagination, {
      useMaxLimit: true
    });

    this.pagination.queryFirstPage();
  }

  handlePaginateChange(lists: List[]) {
    this.lists = lists;
    this.selection.items = this.lists;
  }

  createListAndConfirm() {
    const list = new List({
      name: this.listName,
      status: 3
    });

    this.listService.save(list).subscribe((response) => {
      this.dialogRef.close([response]);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
