<div mat-dialog-title style="text-align: center">Switch user</div>

<mat-divider></mat-divider>

<div mat-dialog-content style="width: 500px">
  <div class="mb-6">
    <input
      type="text"
      [(ngModel)]="query"
      (ngModelChange)="this.inputDebounce.next($event)"
      class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500" />
  </div>

  <div class="max-h-96 overflow-y-auto px-2">
    <button
      *ngFor="let user of users"
      (click)="dialogRef.close(user)"
      class="my-2 flex w-full cursor-pointer items-center gap-4 rounded-md p-2 shadow-sm transition-shadow duration-500 ease-in-out hover:shadow-inner">
      <img class="h-10 w-10 rounded-full" [src]="user.photo_url + '?width=40&height=40'" [alt]="user.firstname" />

      <div class="font-medium">
        <div>{{ user.firstname }}</div>
        <div class="text-sm text-gray-500">ID: {{ user.id }}</div>
      </div>
    </button>
  </div>
</div>
