import { Environment } from '~types/index';
import packageJson from '../../package.json';

// DEV CONFIGURATION WITH DEVELOPEMENT OPTIMIZATION
export const environment: Environment = {
  production: false,
  version: packageJson.version,
  config: {
    FEATURE_FLAGS: {
      VENUES: true
    },
    ANALYTICS: {
      TRACKER: 'f915c743-00ff-471e-bacb-e4dbd741233b',
      URL: 'https://analytics.getwemap.com',
      CFETRACKER: null,
      MATOMO_URL: 'https://analytics.maaap.it',
      MATOMO_TOKEN: 'd331dd0fc73070a856a92c574d6868ff',
      MATOMO_SITE_ID: '1'
    },
    API_CONFIG: {
      API_URL: 'https://apidev.maaap.it',
      API_VERSION: '/v2.2',
      PRINT_URL: 'https://printdev.maaap.it',
      TILES_URL: 'https://vector.getwemap.com',
      CLIENT_ID: 'MKyWkOj6OXeGTWSg1Rm96sbxjnw9UbVVI0rdB6tT'
    },
    FACEBOOK_API: {
      APP_ID: '127303001254122'
    },
    RTFM: {
      URL: 'https://developersdev.maaap.it/'
    },
    INSTAGRAM_API: {
      URL: 'http://prodev.maaap.it/scripts/instagram.html'
    },
    STRIPE_API: {
      KEY: 'pk_test_3VKe36Jsw4Tz33HBfzvSoOU6'
    },
    MAPVIEW: {
      URL: 'https://webdev2.maaap.it/mapview'
    },
    GOOGLE_API: {
      KEY: 'AIzaSyBTOdsIkHvfPfi7tfaxE3FZvepo8c-kjKo',
      URL: 'https://sheets.googleapis.com/$discovery/rest?version=v4'
    },
    BASE_LIEUX_API: {
      KEY: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MDczMzUyMjQsInJvbGVzIjpbIlJPTEVfUkVBREVSIl0sImVtYWlsIjoibWMtcGxhY2VzQGdldHdlbWFwLmNvbSJ9.xkLfAuvXMt3qTZdU4aE2djE1zdCkjKZRv82gI9_pvPTlj5YnIEp68jrq_TI4J1SoqgvLirzQTl5xYHisTsrApaU8YQE2qZSCT97qNfbtaIaelTdmvSMR83V_vgVM4237sCbcVWPgmK-AP3GKc71e48ESaKF0nYGUvSaDdwQVn2E'
    },
    BASE_LIEUX_API_BASIC: {
      KEY: 'Y3VzdG9tZXJzQGdldHdlbWFwLmNvbTpCREwyMDIzV2VtYXA='
    },
    BASE_LIEUX_API_V2: {
      KEY: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTA5NjEyNjAsInJvbGVzIjpbIlJPTEVfRU5SSUNITUVOVF9SRUFERVIiXSwiZW1haWwiOiJyZWFkZXJfZW5yaWNobWVudF9hcGlfZG9jc0Bub3ZhY3RpdmUuY29tIn0.0xy9tTxHGfuhHwiD7YQcWsrgZpQer6YdLLoKypSTH3hFz7hq06SZ5R8k4OzWTi9kIGKJWqlDOVp0pr8uR898AI37t5lvXYarspQ4Vegv0rQOxPVqkIPjxebXQhmtmjPS-mOogM9IPIl0oDd4QSwfjnlhRxoO0arIMhtnLpnxA8Y'
    },
    STYLE_URL: {
      URL: 'https://vector.getwemap.com/styles/wemap-v1'
    },
    SCHNEIDER: {
      CLIENT_ID: 'wemap_D3849_UAT',
      CLIENT_SECRET: 'vg8GyVgIouAi6EliZT8k8AATegGC51SNmSqVIQgMuJkoD9LFbNtmypjky0TXmqsW'
    },
    REMOTE_DATA_LOADER: {
      URL: 'https://remote-data-loader.getwemap.workers.dev'
    }
  }
};
