export class List {
  id: number;
  author: {
    name: string;
    photo_url: string;
  };
  created: Date;
  updated: Date;
  description: string;
  external_data: any;
  image_url: string;
  is_contributive: boolean;
  itinerary: boolean;
  name: string;
  status: number;
  type: number;
  user: any;
  category: any;

  selected?: boolean;

  constructor(data: any) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        switch (key) {
          case 'created':
          case 'updated':
            this[key] = new Date(data[key]);
            break;
          default:
            this[key] = data[key];
            break;
        }
      }
    }
  }
}
