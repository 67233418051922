import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { TABS } from '../livemap.component';

interface State {
  type: `${(typeof TABS)[number]['id'] | '*'}:${string}` | 'initial';
  data?: Record<string, any>;
}

@Injectable()
export class LivemapViewStateService {
  private state: BehaviorSubject<State> = new BehaviorSubject<State>({
    type: 'initial'
  });

  // Getter for pinpoints
  get state$(): Observable<State> {
    return this.state.asObservable();
  }

  dispatchEvent(event: State) {
    this.state.next(event);
  }
}
