import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cut',
  pure: false
})
export class CutPipe implements PipeTransform {
  transform(value: string, completeWords = false, limit = 25, ellipsis = '...') {
    if (!value) {
      return '';
    }
    if (!limit) {
      return value;
    }
    if (value.length <= limit) {
      return value;
    }

    value = value.substring(0, limit);
    if (completeWords) {
      let lastspace = value.lastIndexOf(' ');
      if (lastspace !== -1) {
        //Also remove . and , so its gives a cleaner result.
        if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
          lastspace = lastspace - 1;
        }
        value = value.substr(0, lastspace);
      }
    }

    return value + (ellipsis || ' …');
  }
}
