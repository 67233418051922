import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ActionsType } from './notification.service';

@Component({
  selector: 'app-notification',
  styleUrls: ['notification.component.scss'],
  templateUrl: 'notification.component.html'
})
export class NotificationComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public notification: {
      icon: string;
      message: string;
      actions?: ActionsType;
    }
  ) {}
}
