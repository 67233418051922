import { Subscriber } from 'rxjs';

export interface BulkError {
  id: number;
  detail?: string;
}
export interface BulkResponse {
  data: {
    total: number;
    errors: BulkError[];
  };
}

type BulkOperation<T> = (items: T[], args: any) => Promise<BulkResponse>;

const BATCH_SIZE = 1000;

const batch = <T>(
  op: BulkOperation<T>,
  selectedItems: T[],
  args?: any,
  progressObserver: Subscriber<number> | null = null,
  batchSize = BATCH_SIZE
) => {
  let index = 0;

  const responseFinal: BulkResponse = {
    data: {
      total: 0,
      errors: []
    }
  };

  return new Promise<BulkResponse>((resolve): Promise<BulkResponse> => {
    if (!selectedItems.length) {
      resolve(responseFinal);
      return;
    }

    const nextBatch = () =>
      op(selectedItems.slice(index, index + batchSize), args).then((response: any) => {
        if (response.total !== undefined && response.errors !== undefined) {
          responseFinal.data.total += response.total;
          responseFinal.data.errors.push(...response.errors);
        } else {
          responseFinal.data.total += response.data.total;
          responseFinal.data.errors.push(...response.data.errors);
        }

        if (progressObserver) {
          progressObserver.next(index / selectedItems.length);
        }

        index += batchSize;

        if (index < selectedItems.length) {
          return nextBatch();
        } else {
          if (progressObserver) {
            progressObserver.next(1);
          }

          resolve(responseFinal);
        }
      });

    return nextBatch();
  });
};

export default batch;
